import { defineStore } from 'pinia'
export const useAppStore = defineStore('id', {
    state: () => ({
        cc: 1231231231,
        router: [] as any,
        topBarIndex: 0,
        lekData: [] as any,//lek的页面数据，单独存放
        moveDistance: 0,
        lang: 'en' as any,
        sortDetailData: [] as any,
        bodyRef: [] as any,
        /**是否登录 */
        is_login: false,
        /**控制显示登录框 */
        show_login_bar: false

    }),
    actions: {
        /**设置登录状态 */
        setLoginState(state: boolean) {
            return this.is_login = state
        },
        /**设置登录框显示 */
        setLoginBarState(state: boolean) {
            return this.show_login_bar = state
        },
        getCC() {
            return this.cc
        },
        setRouter(src: any) {
            this.router = src
        },
        getRouter() {
            return this.router
        },
        setTopBarIndex(index: number) {
            this.topBarIndex = index
        },
        getTopBarIndex() {
            return this.topBarIndex
        },
        setLekData(data: any) {//因为地图页数据接收方式有所差异，所以多写一个数据存储位置
            this.lekData = data
        },
        getLekData() {
            return this.lekData
        },
        getMoveDistance() {
            return this.moveDistance
        },
        setMoveDistance(moveDistance: number) {
            this.moveDistance = moveDistance
        },
        getLang(): 'en' | 'cn' {
            return this.lang
        },
        setLang(lang: string) {
            this.lang = lang
        },

        getSortDetailData() {
            return this.sortDetailData
        },
        /**
         * 设置分类页面的详情信息
         * @param data 
         */
        setSortDetailData(data: any) {
            this.sortDetailData = data
        },
        /**
         * 获取页面载体ref
         * @returns 
         */
        getBodyRef() {
            return this.bodyRef
        },
        /**
         * 设置页面载体ref
         * @param ref 
         */
        setBodyRef(ref: any) {
            this.bodyRef = ref
        },
        ///////////////////////////////////////////////////////
        getPageID(location: string) {
            const cn: any = {
                home: 2,//首页广告
                links: 67,//友情链接
                recordNumber: 74,//备案号
                friends: 76,//合作伙伴
                roundBox: 42,//主页功能按钮（圆形）
                introduce: 52,//介绍
                news: 46,//资讯
                photoID: 106,//照片识别
                aboutUs: 36,//关于我们
                aboutUsDOLPHIN: 37,//关于我们 -- 关于iDOLPHIN
                aboutUsUSE: 38,//关于我们 -- 版本和使用协议
                aboutUsCall: 39,//关于我们 -- 联系我们
                populationResearch: 28,//科学研究 -- 小种群研究
                sortActions: 235,//保护证据 -- Actions
            }
            const en: any = {
                home: 82,
                links: 178,
                recordNumber: 179,
                friends: 180,
                roundBox: 175,
                introduce: 177,
                news: 176,
                photoID: 204,
                aboutUs: 171,
                aboutUsDOLPHIN: 172,
                aboutUsUSE: 173,
                aboutUsCall: 174,
                populationResearch: 164,
                sortActions: 236,

            }
            if (this.lang == 'cn') {
                return cn[location]
            } else {
                return en[location]
            }
        },
        /**
         * Tmap
         * @param state 
         * @returns 
         */
        tMapState(state: string) {
            const cn: any = {
                PID_ID: 104,
                LEK_ID: 19,
                MSN_ID: 21,
                PID: '个体照片识别',
                LEK: '当地生态知识',
                MSN: '海洋搁浅网络',
                dataNull: '暂时没有数据',
                all: '全部',
                selectDateTip: '结束日期不能小于开始日期！',
                selectWorQNotData: '该物种或所选的区域暂时没有数据哦~',
                getDataSuccess: '数据获取成功！',
                getDataFail: '数据获取失败！',
                selectSpecies: '选择物种',
                selectPopulation: '选择种群',
                selectIndividual: '选择个体',
                placeHolder: '可以在输入框中查找个体',
                selectDate: '选择日期',
                beginDate: '开始日期',
                endDate: '结束日期',
                reset: '重置',
                confirm: '确定',
                cancel: '取消',
                sourceMap: '原始地图',
                tgMsg: '统计信息',
                baseMsg: '数据库信息',
                zhinan: '使用指南',
                end: '结束',
                up: '上一页',
                down: '下一页',
                exportCsv: '下载开源数据',
                loginTip:'Please log in',
                loginMsg:'完成登录 或 注册后才能导出文件',
                lessExportDate:'导出数据的时间不能小于2021-01-01',
                exportSelectSpecies:'请先选择物种',
                exportSelectPopulation:'请先选择种群',
                loading:'正在导出数据，请稍等...',
                IndividualId:'个体 ID',
                longitude:'经度',
                latitude:'纬度',
                time:'时间',
                
            }
            const en: any = {
                PID_ID: 155,
                LEK_ID: 156,
                MSN_ID: 157,
                PID: 'Photo Identification',
                LEK: 'Local Ecological Knowledge',
                MSN: 'Marine Stranding Network',
                dataNull: 'DATA NULL',
                all: 'All',
                selectDateTip: 'Begin date can`t bigger than end date!',
                selectWorQNotData: 'This Species or Area haven`t data!',
                getDataSuccess: 'Get Data Successfully!',
                getDataFail: 'Get Data Fail！',
                selectSpecies: 'Select species',
                selectPopulation: 'Select population',
                selectIndividual: 'Select individual',
                placeHolder: 'Type to search individual',
                selectDate: 'Select date',
                beginDate: 'Begin',
                endDate: 'End',
                reset: 'Reset',
                confirm: 'Confirm',
                cancel: 'Cancel',
                sourceMap: 'MAP',
                tgMsg: 'STATISTICS',
                baseMsg: 'DATABASE',
                zhinan: 'GUIDE',
                end: 'END',
                up: 'Previous age',
                down: 'Next Page',
                exportCsv: 'Download open data',
                loginTip:'Please log in',
                loginMsg:'Export files only after completing login or registration',
                lessExportDate:'The time for exporting data cannot be less than January 1, 2021',
                exportSelectSpecies:'Please select a species first',
                exportSelectPopulation:'Please select the population first',
                loading:'DownLoading...',
                IndividualId:'Individual ID',
                longitude:'longitude',
                latitude:'latitude',
                time:'time',
            }
            if (this.lang == 'cn') {
                return cn[state]
            } else {
                return en[state]
            }
        },
        /**
         * main
         * @param state 
         * @returns 
         */
        systemMode(state: string) {
            const cn: any = {
                connectNet: '关联网站',
                home: '首页',
                to: '回到',
                top: '顶部',
                Login: '登录',
                Register: '注册',
                phone: '手机号',
                username: '姓名',
                password: '密码',
                captcha: '验证码',
                getCaptcha: '获取验证码',
                wrongCaptcha: '错误的验证码',
                enter_phone: '请输入手机号',
                enter_name: '请输入姓名',
                enter_password: '请输入密码',
                enter_captcha: '请输入验证码',
                phoneLengthOf11: '请输入长度为11为的手机号',
                passwordLengthOf6_32: '密码的长度为6-32位，且不能包含特殊字符',
                phoneIsRegistered: '该手机号已被注册',
                loginSuccessful: '登录成功',
                registerSuccessful: '注册成功',
                Conservation_Evidence: '保护证据',
                newStudies: '提交新证据',
                studiesList: '已提交的证据',
                info: '个人信息',
                changePassword: '修改密码',
                logOut: '退出登录',
                documentId: '文档ID',
                title: '标题',
                setting: '操作',
                newPassword: '新密码',
                submit: '提交',
                author: '作者',
                Publication_time: '出版时间',
                reference: '参考文献',
                keyWords: '关键词',
                KeyMessages: '关键信息',
                AssociateActions: '行动',
                save: '保存内容',
                change: '修改内容',
                summary: '摘要',
                submitContent: '内容不能为空',
                submitTitle: '标题不能为空',
                submitAuthor: '作者不能为空',
                deleteItem: '确认删除该条数据吗？',
                isLogout: '确认退出登录吗？',
                download_select: '选择',
                download_fullText: '文本格式',
                download_results: '下载',

            }
            const en: any = {
                connectNet: 'WEBSITE',
                home: 'Home',
                to: 'TO',
                top: 'TOP',
                Login: 'Login',
                Register: 'Register',
                phone: 'Phone',
                username: 'Name',
                password: 'Password',
                captcha: 'Captcha',
                getCaptcha: 'Get Captcha',
                wrongCaptcha: 'Wrong Captcha',
                enter_phone: 'Please enter the phone',
                enter_name: 'Please enter the name',
                enter_password: 'Please enter the password',
                enter_captcha: 'Please enter the captcha',
                phoneLengthOf11: 'Please enter a phone number with a length of 11 digits',
                passwordLengthOf6_32: 'The length of the password is 6-32 bits and cannot contain special characters',
                phoneIsRegistered: 'The phone number has been registered, please log in directly',
                loginSuccessful: 'Login successful',
                registerSuccessful: 'Register successful',
                Conservation_Evidence: 'Conservation Evidence',
                newStudies: 'New Studies',
                studiesList: 'Studies List',
                info: 'Info',
                changePassword: 'Change Password',
                logOut: 'Log Out',
                documentId: 'Document ID',
                title: 'Title',
                setting: 'Setting',
                newPassword: 'New Password',
                submit: 'Submit',
                author: 'Author',
                Publication_time: 'Publication Time',
                reference: 'Reference',
                keyWords: 'Key Words',
                KeyMessages: 'Key Messages',
                AssociateActions: 'Associate Actions',
                save: 'Save',
                change: 'Save',
                summary: 'Summary',
                submitContent: 'Null Content!',
                submitTitle: 'Null Title!',
                submitAuthor: 'Null Author!',
                deleteItem: 'Are you sure to delete this item?',
                isLogout: 'Are you  sure to logout?',
                download_select: 'Select',
                download_fullText: 'Full Text(.txt)',
                download_results: 'Download',

            }
            if (this.lang == 'cn') {
                return cn[state]
            } else {
                return en[state]
            }
        },
        homeMode(state: string) {
            const cn: any = {
                more: '更多',
                adopt: '物种认领',
                wantAdopt: '我要认领',
                partner: '合作伙伴',
                photoID: '照片识别',
                news: '资讯',
            }
            const en: any = {
                more: 'More',
                adopt: 'ADOPT A DOLPHIN',
                wantAdopt: 'TO ADOPT',
                partner: 'PATNERSHIP',
                photoID: 'Photo-ID',
                news: 'NEWS',
            }
            if (this.lang == 'cn') {
                return cn[state]
            } else {
                return en[state]
            }
        },
        /**
         * 照片识别
         * @param state 
         * @returns 
         */
        photoMode(state: string) {
            const cn: any = {
                title: '照片识别',
                home: '首页',
                selectSpecies: '选择物种',
                selectPopulation: '选择种群',
                allSpecies: '全部物种',
                allPopulation: '全部种群',
                byTime: '时间排序',
                byName: '个体名称排序',
                fin: '背鳍',
                left: '左背鳍',
                right: '右背鳍',
                dataNull: '数据为空',
                up: '上一页',
                down: '下一页',
                page: '页',
                NO: '编号',
                lnglat: '经/纬度',
                copyright: '版权',
                author: '作者',
                organization: '机构',
                fund: '资助'
            }
            const en: any = {
                title: 'Photo-ID',
                home: 'Home',
                selectSpecies: 'Select species',
                selectPopulation: 'Select population',
                allSpecies: 'All',
                allPopulation: 'All',
                byTime: 'By time',
                byName: 'By name',
                fin: 'fin',
                left: 'Left',
                right: 'Right',
                dataNull: 'DATA NULL',
                up: 'Previous Page',
                down: 'Next Page',
                page: ' Pages',
                NO: 'NO',
                lnglat: 'lng/lat',
                copyright: 'copyright',
                author: 'author',
                organization: 'organization',
                fund: 'fund'
            }
            if (this.lang == 'cn') {
                return cn[state]
            } else {
                return en[state]
            }
        },
        /**
         * 分类
         * @param state 
         * @returns 
         */
        sort(state: string) {
            const cn: any = {
                all: '全部',
                populations: '种群',
                keywords: '关键词',
                researchType: '研究方向',
                habitat: '生态系统',
                threat: '威胁因子',
                documentType: '文件类型',
                screen: '搜索',
                actionsFound: '条结果',
                refine: '筛选条件',
                search: '搜索',
                keyWords: '请输入关键字',
                study: 'Study',
                published: 'Published',
                actions: 'Actions',
                Published: '出版时间',
                Research: "研究",
                Planning: "计划",
                Implementation: '实施',
                Evaluation: "评估",
                Category: "种类",
                Edit: '编辑',
                Delete: '删除'
            }
            const en: any = {
                all: 'General',
                populations: 'Populations',
                keywords: 'Keywords',
                researchType: 'Research Type',
                habitat: 'Habitat',
                threat: 'Threat',
                documentType: 'Document Type',
                screen: 'Refresh results',
                actionsFound: '  Actions found',
                refine: 'Refine',
                search: 'Search',
                keyWords: 'Enter Text...',
                study: 'Study',
                published: 'Published',
                actions: 'Actions',
                Published: 'Published',
                Research: "Research",
                Planning: "Planning",
                Implementation: 'Implementation',
                Evaluation: "Evaluation",
                Category: "Category",
                Edit: 'Edit',
                Delete: 'Delete'
            }
            if (this.lang == 'cn') {
                return cn[state]
            } else {
                return en[state]
            }
        }
    }
})

export function UseAppStore() {
    return useAppStore()
}
