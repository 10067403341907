import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {  createPinia  } from 'pinia'
// import store from './store'

import 'normalize.css/normalize.css'
import 'element-plus/dist/index.css'
import './assets/css/layout.css'
import './assets/css/bootstrap.min.css'
import 'animate.css'
import './styles/icon.css'
import cookies from 'vue-cookies'
import 'strap-trousers/style/index.css'
// import 'lib-flexible'
// createApp(App)
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.use(createPinia()).use(router, ElementPlus, {
    locale: zhCn,
}).mount('#app')
app.config.globalProperties.$cookies = cookies
