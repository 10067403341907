import { createRouter, createWebHistory } from 'vue-router'

import { routes } from './routes'
import { setupPageTitleGuard } from './guard'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


setupPageTitleGuard(router);


router.onError((to, from ,e) => {
  console.debug(to , from ,e)
})

export default router
