import type { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
	// {
	// 	path: '/',
	// 	name: 'redirect',
	// 	redirect: '/',
	// },
	{
		path: '/',
		name: 'layout',
		component: () => import("@/layout/index.vue"),
		children: [
			{
				path: '/',
				name: 'home',
				component: () => import("@/pages/home/index.vue"),
				meta: {
					title: '首页'
				}
			},
			{
				path: '/specialInfoDetial',
				name: 'specialInfoDetial',
				component: () => import("@/pages/home/specialInfoDetial/index.vue"),
				meta: {
					title: '资讯详情'
				}
			},
			{
				path: '/richText',
				name: 'richText',
				component: () => import("@/pages/richText/index.vue"),
				meta: {
					title: 'iDolphin'
				}
			},
			{
				path: '/lek',
				name: 'lek',
				component: () => import("@/pages/lek/index.vue"),
				meta: {
					title: '生态观豚'
				}
			},
			{
				path: '/pid',
				name: 'pid',
				component: () => import("@/pages/pid/index.vue"),
				meta: {
					title: '照片识别'
				}
			},
			{
				path: '/demo',
				name: 'demo',
				component: () => import("@/pages/demo/demo.vue"),
				meta: {
					title: '临时测试'
				}
			},
			{
				path: '/modelPageList',
				name: 'modelPageList',
				component: () => import("@/pages/modelPageList/index.vue"),
				meta: {
					title: 'modelPage'
				}
			},
			{
				path: '/TransferStation',
				name: 'TransferStation',
				component: () => import("@/pages/TransferStation/index.vue"),
				meta: {
					title: 'iDolphin'
				}
			},
			{
				path: '/test',
				name: 'test',
				component: () => import("@/pages/about/test.vue"),
				meta: {
					title: 'modelPage'
				}
			},
			{
				path: '/tools',
				name: 'tools',
				component: () => import("@/pages/pid/tools/findErrorImg.vue"),
				meta: {
					title: '测试工具'
				}
			},
			{
				path: '/sort',
				name: 'sort',
				component: () => import("@/pages/sort/index.vue"),
				meta: {
					title: '分类查询'
				}
			},
			{
                path: '/sort_detail',
				name: 'sort_detail',
				component: () => import("@/pages/sort/detail.vue"),
				meta: {
                    title: '详情'
				}
			},
            {
                path: '/article',
                name: 'article',
                component: () => import("@/pages/article/index.vue"),
                meta: {
                    title: '文章管理'
                }
            },
		]
	},
]