import { Router,useRoute } from 'vue-router';

export function setupPageTitleGuard(router: Router) {

  const baseTitle = process.env.VUE_APP_TITLE || '';

  router.afterEach((to) => {
    if (to.name === 'richText') { return }
    else if (to.name === 'modelPageList') {
      document.title = 'idolphin'
    } else {

      const pageTitle = to.meta.title;
      if (pageTitle) {
        document.title = `${pageTitle} | ${baseTitle}`;
      } else {
        document.title = baseTitle;
      }
    }
  });
}