import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onBeforeMount } from 'vue';
import cookies from 'vue-cookies';
import { useAppStore } from '@/store';
export default {
  setup(__props) {
    const cookies_ = cookies;
    const store = useAppStore();
    onBeforeMount(() => {
      let lang = cookies_.get('system_lang');
      if (!lang || lang == null) cookies_.set('system_lang', 'cn');
      store.setLang(lang !== null && lang !== void 0 ? lang : 'cn');
      checkLogin();
    });
    const checkLogin = () => {
      let userInfo = cookies_.get('idolphin_userInfo');
      if (userInfo && userInfo != null && userInfo.id > 0) store.setLoginState(true);
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view, {
        class: "body"
      });
    };
  }
};